@import '../../../scss/base.module';

.signup-form {
  &__field {
    margin-bottom: $spacing-xs;

    button {
      span {
        @include font-bold;
      }

      width: 100%;
    }

    @include from-tablet {
      margin-bottom: $spacing-m;
    }
  }

  &__opt-in-checkbox {
    margin-top: $spacing-s;

    @include from-tablet {
      margin-bottom: $spacing-l;
    }

    > span {
      @include font-s;
      line-height: 1.5;
    }
  }

  &__continue {
    width: 100%;
    margin: $spacing-s 0;
    box-sizing: border-box;

    * {
      width: 100%;

      @include from-tablet {
        margin: 0;
      }
    }

    span {
      @include font-bold;
    }

    @include from-tablet {
      width: auto;
      margin: 0;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
      order: 3;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    @include from-tablet {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__go-back {
    display: none;

    @include from-tablet {
      display: inline-block;
      margin-right: $spacing-s;
      margin-bottom: 0;

      span {
        @include font-bold;
      }
    }
  }

  &__submit {
    display: inline-block;

    button {
      @include font-bold;

      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
    }
  }

  &__actions {
    width: 100%;

    div {
      box-sizing: border-box;
    }

    @include from-tablet {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      div {
        margin-bottom: 0;
      }
    }
  }

  &__terms-and-conditions {
    width: 100%;

    @include from-tablet {
      order: 1;
      flex-grow: 1;
    }
  }

  &__toggle {
    @include from-tablet {
      order: 2;
    }
  }

  &__error {
    @include font-s;
    @include font-bold;

    color: $danger;
    margin-top: $spacing-m;

    @include from-laptop {
      margin-top: $spacing-xs;
    }
  }
}
